
import { LineLayout } from '@/layout'
import React, { useEffect, useState } from 'react'
import { LiveCover } from '@/components/liveCover'
import { store } from '@/store'
import { useHistory } from 'react-router-dom'
import { Collapes } from '@/components/collapse'
import { Loading1 } from '@/components/loading'
import { PageBar } from '@/components/bar';
export const LiveNavigation = () => {
    const history = useHistory()
    const [cameras] = useState(store.getState().cameras)
    const [camerasCover] = useState(store.getState().camerasCover)
    const pageName = store.getState().projectInfo.name;
    const clickFullScreen = (id: number) => {
        history.push(`/live?cameraId=${id}`)
    }
    const globalCameras = cameras.filter((item: any) => {
        return item.workStationCode === null ||
            item.workStationCode === '101' ||
            item.workStationCode === '102' ||
            item.workStationCode === '103'
    })
    const workStationCameras = cameras.filter((item: any) => {
        return item.workStationCode !== null &&
            item.workStationCode !== '101' &&
            item.workStationCode !== '102' &&
            item.workStationCode !== '103'
    })
    const workStationCamerasFirst = workStationCameras[0]
    const globalCamerasFirst = globalCameras[0]
    workStationCameras.shift()
    globalCameras.shift()
    const clickToggle = () => {
        alert('12')
    }
    return <React.Fragment>
        <PageBar title={'实况直播'} />
        {workStationCamerasFirst === undefined ? <Loading1 /> :
            <React.Fragment>
                <LineLayout justify='between' padding={[20, 0, 0, 20]}>
                    <div style={{ color: '#fff', fontSize: '20px' }}>{workStationCameras.length + 1}个加工站直播</div>
                </LineLayout>
                <LiveCover
                    deviceSerial={workStationCamerasFirst.deviceSerial}
                    channelNo={workStationCamerasFirst.channelNo}
                    cameraId={workStationCamerasFirst.id}
                    name={workStationCamerasFirst.name}
                    clickFullScreen={() => { clickFullScreen(workStationCamerasFirst.id) }}
                    coverUrl={camerasCover ? camerasCover[workStationCamerasFirst.id] : null}
                    url={workStationCamerasFirst.yS7UrlFluency} />

                <Collapes>
                    {
                        workStationCameras.map((item: any, idx: number) => {
                            return <LiveCover
                                deviceSerial={item.deviceSerial}
                                channelNo={item.channelNo}
                                cameraId={item.id}
                                name={item.name}
                                clickFullScreen={() => { clickFullScreen(item.id) }}
                                coverUrl={camerasCover ? camerasCover[item.id] : null}
                                url={item.yS7UrlFluency} />
                        })
                    }

                </Collapes>
                <LineLayout justify='between' padding={[20, 0, 0, 20]}>
                    <div style={{ color: '#fff', fontSize: '20px' }}>{globalCameras.length + 1}个车间直播</div>
                </LineLayout>
                <LiveCover
                    deviceSerial={globalCamerasFirst.deviceSerial}
                    channelNo={globalCamerasFirst.channelNo}
                    cameraId={globalCamerasFirst.id}
                    name={globalCamerasFirst.name}
                    clickFullScreen={() => { clickFullScreen(globalCamerasFirst.id) }}
                    coverUrl={camerasCover ? camerasCover[globalCamerasFirst.id] : null}
                    url={globalCamerasFirst.yS7UrlFluency} />
                <Collapes>
                    {
                        globalCameras.map((item: any, idx: number) => {
                            return <LiveCover
                                deviceSerial={item.deviceSerial}
                                channelNo={item.channelNo}
                                cameraId={item.id}
                                name={item.name}
                                clickFullScreen={() => { clickFullScreen(item.id) }}
                                coverUrl={camerasCover ? camerasCover[item.id] : null}
                                url={item.yS7UrlFluency} />
                        })
                    }
                </Collapes>
            </React.Fragment >
        }
    </React.Fragment >
}
