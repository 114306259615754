import React, { useState } from 'react';
import { PageBar } from '@/components/bar';
import { store } from '@/store';
import { Card } from '@/components/card';
import { searchParams } from '@/utils';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd-mobile';
export const EquNavigation = () => {
    const history = useHistory();
    const [lineCode] = useState(searchParams().lineCode);
    const pageName = store.getState().projectInfo.name;
    return <React.Fragment>
        <PageBar title={'设备管理'} />
        <Card
            center='all'
            width={345}
            margin={[10, 0, 10, 15]}
            height={200}
            icon='tips.png'
            title='点击选择功能模块'
        >
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', width: '320px' }}>
                {/* <Button

                    type='primary'
                    style={{ width: '150px', marginBottom: '20px' }}
                    onClick={() => history.push(`/line/equipment?lineCode=${lineCode}`)}
                >
                    {'概览'}</Button> */}
                <Button

                    type='primary'
                    style={{ width: '150px', marginBottom: '20px' }}
                    onClick={() => history.push(`/line/oven?lineCode=${lineCode}`)}
                >
                    {'烘箱'}</Button>
                <Button

                    type='primary'
                    style={{ width: '150px', marginBottom: '20px' }}
                    onClick={() => history.push(`/iot/rubberTank?lineCode=${lineCode}`)}
                >
                    {'胶罐'}</Button>
                <Button

                    type='primary'
                    style={{ width: '150px', marginBottom: '20px' }}
                    onClick={() => history.push(`/line/workStationNavigation?lineCode=${lineCode}`)}
                >
                    {'加工站'}</Button>
                <Button

                    type='primary'
                    style={{ width: '150px', marginBottom: '20px' }}
                    onClick={() => history.push(`/iot/logistics?lineCode=${lineCode}`)}
                >
                    {'物流'}</Button>
            </div>
        </Card>
    </React.Fragment >
}
