import React, { useMemo, useState } from "react";
import service from '@/api/service'
import Qs from 'qs'
import { Icon } from '@/components/icon'
import { LineLayout } from "@/layout";
import './index.scss'
import { Card } from "../card";
import { Loading2 } from '@/components/loading'
import { setCamerasCover, store } from "@/store";
import { Toast } from 'antd-mobile';
interface Props {
    name: string,
    coverUrl: string,
    channelNo?: number,
    deviceSerial?: string,
    url: '',
    cameraId?: '',
    clickFullScreen?: Function
}

const LiveCover = (props: Props) => {
    const [coverLoading, setCoverLoading] = useState(false)
    const [coverUrl, setCoverUrl] = useState(props.coverUrl)
    const id = new Date().getTime().toString() + Math.random();
    //点击更新封面
    const onClickReloadCover = () => {
        setCoverLoading(true)
        service({
            url: 'https://open.ys7.com/api/lapp/device/capture',
            method: 'post',
            data: Qs.stringify({
                accessToken: store.getState().ysyToken,
                deviceSerial: props.deviceSerial,
                channelNo: props.channelNo
            })
        }).then((res: any) => {
            Toast.success('封面已刷新', 2, null, true);
            setCoverLoading(false)
            if (res.code === '200') {
                let camerasCover = JSON.parse(JSON.stringify(store.getState().camerasCover))
                if (camerasCover) {
                    camerasCover[props.cameraId] = res.data.picUrl
                    setCoverUrl(res.data.picUrl)
                    setCamerasCover(camerasCover)
                }


                Toast.success('封面已刷新', 2, null, true);
            } else {
                Toast.fail(res.msg, 2, null, true);
            }
        }).catch((err) => {
            console.log(err)
            Toast.fail(err.msg, 2, null, true);
        })
    }
    const onClickPlay = () => {
        props.clickFullScreen()
    }
    return (
        <Card
            customBar={<React.Fragment />}
            width={345}
            height={230}
            margin={[10, 0, 0, 15]}
        >
            <LineLayout justify='start' direction='column'>
                <div className='live-cover' id={`live-cover-${id}`} onClick={() => onClickPlay()}>


                    {
                        coverUrl?
                        <img alt='' src={coverUrl} width={345} height={180} />: <Icon iconName='camera.png' size={4}/>
                      
                    }
                    
                    {
                        coverLoading ? <div className='loading-mask'>
                            <Loading2 />
                        </div> : null
                    }
                </div>
                <LineLayout justify='between' width={340} padding={10} >
                    <div className='camera-name'> {props.name}</div>
                    <div className='live-preview-btns'>
                        <div onClick={() => onClickPlay()}>
                            <Icon iconName='play.png' size={1.2} />
                        </div>
                        <div onClick={() => onClickReloadCover()}>
                            <Icon iconName='reload.png' size={1.2} />
                        </div>
                    </div>
                </LineLayout>
            </LineLayout>
        </Card>
    )
}

export { LiveCover }
