import React from 'react';
import { CardBar, RegionBar } from '@/components/bar';
import { PageBar } from '@/components/bar';
import { store } from '@/store';
import { Card } from '@/components/card';
import { LineLayout } from '@/layout';
import { Image } from '@/components/image';
import { BasicUnderLine } from '@/components/underLine';
import { DigitalBlock } from '@/components/digitalBlock';
import * as API from '@/api/request';
import { useRequest } from 'ahooks';
import Loading from '@/components/loading/loading';
import { Error } from '@/components/error';
import { searchParams, handleArrayToObject } from '@/utils';

const lineCode = searchParams().lineCode;
let content = <Loading />;
let glueBucketCurrentParameters: any;

export const RubberTank = () => {
    let { data, loading, error } = useRequest(() => {
        return Promise.all([
            API.Equipment.getGlueBucketCurrentParameters(lineCode),
        ])
    })

    if (loading) {
        return <><PageBar title={'胶罐'} />{content}</>;
    }

    if (error) {
        return <><PageBar title={'胶罐'} /><Error /></>
    } else {
        [glueBucketCurrentParameters] = data;
    }

    return <React.Fragment>
        <PageBar title={'胶罐'} />
        {/* <RegionBar title='胶罐' /> */}
        <Card
            customBar={<React.Fragment />}
            width={345}
            margin={[10, 0, 10, 15]}
        >
            <LineLayout justify='start' direction='column' padding={[10, 0, 0, 0]} width={353}>
                <Image url={'iotRubberTank.png'} height={160} width={325} />
                {
                    store.getState().glueBuckets.map((item: any, index: number) => {
                        return <LineLayout justify='around' width={325} key={index}>
                            <CardBar title={`${index + 1}-${item.name}`} icon={''} hideForward size={0.8} width={135} />
                            {index === store.getState().glueBuckets.length - 1 ? '' : <BasicUnderLine />}
                            {
                                handleArrayToObject(glueBucketCurrentParameters, 'bucketCode')[item.code] !== undefined ?
                                    <DigitalBlock
                                        value={handleArrayToObject(glueBucketCurrentParameters, 'bucketCode')[item.code].positionInMillimeter}
                                        size={0.8} unit='mm' width={115} textAlign='right' /> :
                                    <p style={{ fontWeight: 'bold', width: '72px', textAlign: 'right' }}>--</p>
                            }
                            {
                                handleArrayToObject(glueBucketCurrentParameters, 'bucketCode')[item.code] !== undefined ?
                                    <DigitalBlock
                                        value={handleArrayToObject(glueBucketCurrentParameters, 'bucketCode')[item.code].pressureInPa / 1000000}
                                        size={0.8} unit='MPa' width={115} textAlign='right' retentionDigit={2} /> :
                                    <p style={{ fontWeight: 'bold', width: '72px', textAlign: 'right' }}>--</p>
                            }
                        </LineLayout>
                    })
                }
            </LineLayout>
        </Card>
    </React.Fragment>
}