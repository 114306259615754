import React, { useState } from "react"
import './index.scss'
interface CollapesProps {
  children:Object | Array<any>
}
export const  Collapes = (props:CollapesProps)=>{
  const clickToggle = ()=>{
    setToggleBtnStatus(!toggleBtnStatus)

  }
  const [toggleBtnStatus,setToggleBtnStatus] = useState(false) 
  return <React.Fragment>
    {toggleBtnStatus? props.children:null}
    <div className='toggle-btn' onClick={clickToggle}>{toggleBtnStatus?'收起':'查看更多'}</div>
  </React.Fragment>                                                                                              

} 