import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { Home } from '@/pages/home';
import * as WorkShop from '@/pages/workShop';
import * as ProductionLine from '@/pages/productionLine';
import * as API from '@/api/request'
import * as IotUpgrade from '@/pages/iotUpgrade'
import { HyshenTest } from '@/pages/developmentTest/hyshen';
import { Loading1 } from '@/components/loading';
import { useMount } from 'ahooks'
import { setCamerasCover, setYs7Token, setProjectInfo, setHasCallButtonSystem, setRobots, setCameras, setInstallLineCodes, setOvenOrRefrigerators, store, setGlueBuckets, setHasStopperWorkStations, setCurrentRobotStatus } from '@/store';
import { searchParams } from '@/utils';
import { PrivateRoute } from '@/components/privateRoute'
import { useState } from 'react';
import { Error } from '@/components/error'
import { Version } from '@/pages/version'
import moment from 'moment';
import { NotFound } from '@/pages/notFound'
import { PageError } from '@/pages/pageError'
import versionConfig from '../version.json'
import service from '@/api/service'
import Qs from 'qs'
import { LivePlayer } from '@/pages/productionLine/home/livePlay';
//检查是否过期
const checkExpirationTime = (time: string) => {
    const expirationTim = new Date(time)
    return expirationTim.getTime() < (new Date()).getTime()
}
//检查缓存配置
const checkProjectConfig = (projectConfig: any, serverCompiledVersion: string) => {
    if (!projectConfig) return true
    if (!projectConfig.appBuildTime) return true
    if (!projectConfig.serverCompiledVersion) return true
    if (projectConfig.appBuildTime !== versionConfig.appBuildTime) return true
    if (projectConfig.serverCompiledVersion !== serverCompiledVersion) return true
    if (checkExpirationTime(projectConfig.expirationTime)) return true
    return false
}
const Routes = () => {
    const [lineCode] = useState(searchParams().lineCode)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    useMount(async () => {
        const setProjectInfoInStore = (projectConfig: any) => {
            setInstallLineCodes(projectConfig.installLineCodes)
            setProjectInfo({
                name: projectConfig.projectInfo.name,
                shortCode: projectConfig.projectInfo.shortCode,
                id: projectConfig.projectInfo.id
            })
            setHasCallButtonSystem(projectConfig.hasCallButtonSystem)
            setCameras(projectConfig.cameras)
            setRobots(projectConfig.robots)
            setOvenOrRefrigerators(projectConfig.ovenOrRefrigerators)
            setGlueBuckets(projectConfig.glueBuckets)
            setHasStopperWorkStations(projectConfig.hasStopperWorkStations)
            //设置相机token
            if (projectConfig.cameras && projectConfig.cameras.length !== 0) {
                setYs7Token(projectConfig.cameras[0].yS7AccessToken)
            }
            setLoading(false)
        }
        //获取相机封面的promise集合
        const getCameraCoverPromiseList = (cameras: Array<any>) => {
            if (!cameras || cameras.length === 0) return []
            return cameras.map(item => {
                return service({
                    url: 'https://open.ys7.com/api/lapp/device/capture',
                    method: 'post',
                    data: Qs.stringify({
                        accessToken: cameras[0].yS7AccessToken,
                        deviceSerial: item.deviceSerial,
                        channelNo: item.channelNo
                    })
                })
            })
        }
        //获取服务器版本号
        const serverCompiledVersion = await API.Config.getServerCompiledVersion().catch(() => setError(true))
        const projectConfig = JSON.parse(localStorage.getItem('projectConfig'))
        const camerasCover = JSON.parse(localStorage.getItem('camerasCover'))
        const lastRobotStatus = JSON.parse(localStorage.getItem('lastRobotStatus'))
        if (checkProjectConfig(projectConfig, serverCompiledVersion)) {
            const [
                projectInfo,
                hasCallButtonSystem,
                robots,
                cameras,
                installLineCodes,
                ovenOrRefrigerators,
                glueBuckets,
                hasStopperWorkStations
            ] = await Promise.all([
                API.Config.getProjectInfo(),
                API.Call.hasCallButtonSystem(lineCode),
                API.Config.getRobots(lineCode),
                API.Config.getCameras(lineCode),
                API.Equipment.getInstalledLineCodes(lineCode),
                API.Config.getOvenOrRefrigerators(),
                API.Config.getGlueBuckets(lineCode),
                API.Config.getHasStopperWorkStations(lineCode)
            ])
            const projectConfig = {
                projectInfo,
                hasCallButtonSystem,
                robots,
                cameras,
                installLineCodes,
                serverCompiledVersion,
                appBuildTime: versionConfig.appBuildTime,
                expirationTime: moment().add(24, 'hours').valueOf(),
                ovenOrRefrigerators,
                glueBuckets,
                hasStopperWorkStations,
                ys7Token: cameras[0].yS7AccessToken,
            }
            localStorage.setItem('projectConfig', JSON.stringify(projectConfig))
            setProjectInfoInStore(projectConfig)
            setLoading(false)
            //获取相机的封面可能会有很多个 ,相机封面获取不阻止app进入
            const camerasCover = {}
            const cameraCoverRequestResult = await Promise.all(getCameraCoverPromiseList(cameras))
            cameras.forEach((item: any, idx: number) => {
                if (cameraCoverRequestResult[idx].data) {
                    camerasCover[item.id] = cameraCoverRequestResult[idx].data.picUrl
                }
            });
            localStorage.setItem('camerasCover', JSON.stringify(camerasCover))
            setCamerasCover(camerasCover)
        } else {
            setProjectInfoInStore(projectConfig)
            setCamerasCover(camerasCover)
            setCurrentRobotStatus(lastRobotStatus)
        }
    })
    if (loading) return <Loading1 />
    if (error) return <Error value='无法获取项目配置' />
    const routes = [
        { path: '/notFound', component: <NotFound />, exact: false },
        { path: '/pageError', component: <PageError />, exact: false },
        { path: '/v', component: <Version />, exact: false },
        { path: '/', component: <Home />, exact: true },
        { path: '/live', component: <LivePlayer />, exact: false },
        { path: '/home', component: <Home />, exact: true },
        { path: '/hyshenTest', component: <HyshenTest />, exact: true },
        { path: '/workShop', component: <WorkShop.Home />, exact: true },
        { path: '/line', component: <ProductionLine.HomeEdition2 />, exact: true, isLine: true },
        { path: '/iot/oven', component: <IotUpgrade.Oven />, exact: false, isLine: true },
        { path: '/iot/rubberTank', component: <IotUpgrade.RubberTank />, exact: false, isLine: true },
        { path: '/iot/robot', component: <IotUpgrade.Robot />, exact: false, isLine: true },
        { path: '/iot/logistics', component: <IotUpgrade.Logistics />, exact: false, isLine: true },
        { path: '/iot/lineEnergy', component: <IotUpgrade.LineEnergy />, exact: false, isLine: true },
        { path: '/iot/environment', component: <IotUpgrade.Environment />, exact: false, isLine: true },
        { path: '/workShop/environment', component: <WorkShop.Environment />, exact: false },
        { path: '/workShop/production', component: <WorkShop.Production />, exact: false },
        { path: '/workShop/quality', component: <WorkShop.Quality />, exact: false },
        { path: '/workShop/equipment', component: <WorkShop.Equipment />, exact: false },
        { path: '/line/equNavigation', component: <ProductionLine.EquNavigation />, exact: false, isLine: true },
        { path: '/line/workStationNavigation', component: <ProductionLine.WorkStationNavigation />, exact: false, isLine: true },
        { path: '/line/liveNavigation', component: <ProductionLine.LiveNavigation />, exact: false, isLine: true },
        { path: '/line/callNavigation', component: <ProductionLine.CallNavigation />, exact: false, isLine: true },
        { path: '/line/production', component: <ProductionLine.Production />, exact: false, isLine: true },
        { path: '/line/quality', component: <ProductionLine.Quality />, exact: false, isLine: true },
        { path: '/line/equipment', component: <ProductionLine.Equipment />, exact: false, isLine: true },
        { path: '/line/environment', component: <ProductionLine.Environment />, exact: false, isLine: true },
        { path: '/line/oven', component: <ProductionLine.Oven />, exact: false, isLine: true },
        { path: '/line/workStation', component: <ProductionLine.WorkStation />, exact: false, isLine: true },
        { path: null, component: null, exact: false },
    ]
    return (
        <Router>
            <Switch>
                {
                    routes.map((item: any, idx: number) =>
                        <PrivateRoute
                            exact={item.exact}
                            path={item.path}
                            content={item.component}
                            key={idx}
                            isLine={item.isLine} />)
                }
            </Switch>
        </Router>
    )
}

export default Routes
