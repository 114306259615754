import service from '../service'

const Equipment = {
    /**
     * 获取今日稼动率
     * @param lineCode 
     * @returns 
     */
    getTodayUtilizationRate: (lineCode?: string): Promise<any> => {
        return service({
            url: '/Equipment/GetTodayUtilizationRate',
            params: { lineCode: lineCode }
        })
    },

    /**
     * 获取电力数据
     */
    getTodayElectricityValues: (lineCode?: string | null, timeRange?: { beginTime: string, endTime: string } | {}): Promise<any> => {
        return service({
            url: '/Equipment/GetElectricityValues',
            params: { lineCode: lineCode, ...timeRange }
        })
    },

    /**
     * 
     * @param lineCode 根据时间区间和产线编号获取累积电量
     * @returns 
     */

    getlectricityValues: (lineCode?: string | null, timeRange?: { beginTime: string, endTime: string } | {}): Promise<any> => {
        return service({
            url: '/Equipment/GetElectricityValues',
            params: { lineCode, ...timeRange }
        })
    },

    /**
     * 获取按时段汇总的稼动率
     * @param lineCode 产线编号
     * @param timeRange 时间区间 {beginTime,endTime}
     * @returns 
     */
    getUtilizationRateByTimeRange: (lineCode?: string | null, timeRange?: { beginTime: string, endTime: string } | {}): Promise<any> => {
        return service({
            url: '/Equipment/GetUtilizationRateByTimeRange',
            params: { lineCode, ...timeRange }
        })
    },

    /**
      * 获取产线的稼动时长和待机时长
      * @param lineCode 产线编号
      * @param timeRange 时间区间 {beginTime,endTime}
      * @returns 
      */
    getUtilizationHours: (lineCode?: string | null, timeRange?: { beginTime: string, endTime: string } | {}): Promise<any> => {
        return service({
            url: '/Equipment/GetUtilizationHours',
            params: { lineCode, ...timeRange }
        })
    },

    /**
     * 
     * @param lineCode 获取产线的当前功率
     * @returns 
     */
    getCurrentElectricityPowerInW: (lineCode?: string): Promise<any> => {
        return service({
            url: 'Equipment/GetCurrentElectricityPowerInW',
            params: { lineCode }
        })
    },


    /**
      * 获取产线按照时间区分的能耗
      * @param lineCode 产线编号
      * @param timeRange 时间区间 {beginTime,endTime}
      * @returns 
      */
    getElectricityQuantityInKWHByTimeRange: (lineCode?: string | null, timeRange?: { beginTime: string, endTime: string } | {}): Promise<any> => {
        return service({
            url: '/Equipment/GetElectricityQuantityInKWHByTimeRange',
            params: { lineCode, ...timeRange }
        })
    },


    /**
     * 获取烘箱冷定形各种状态
     * @param lineCode 
     * @param timeRange 
     * @returns 
     */
    getOvensCurrentParameters: (lineCode?: string): Promise<any> => {
        return service({
            url: '/Equipment/GetOvensCurrentParameters',
            params: { lineCode }
        })
    },


    /**
     * 获取各个烘箱和冷定形状电量
     * @param lineCode 
     * @returns 
     */
    getOvenElectricity: (lineCode?: string, timeRange?: any): Promise<any> => {
        return service({
            url: '/Equipment/GetOvenElectricityQuantityInKWHByTimeRangeByOven',
            params: { lineCode, ...timeRange }
        })
    },


    /**
     * 获取车间全部产线的耗电量
     * @param timeRange 
     * @returns 
     */

    getALlLineElectricityQuantity: (timeRange: any): Promise<any> => {
        return service({
            url: '/Equipment/GetElectricityQuantityInKWHByTimeRangeByLineCode',
            params: { ...timeRange }
        })
    },



    /**
     * 获取所有产线的稼动率 
     * @param timeRange 
     * @returns 
     */
    getAllLineUtilizationRate: (timeRange: any): Promise<any> => {
        return service({
            url: '/Equipment/GetUtilizationRateByTimeRangeByLineCode',
            params: { ...timeRange }
        })
    },

    /**
     * 获取相机的URL
     * @param lineCode 
     * @param workStationCode  
     * @returns 
     */
    getCameras: (lineCode?: string, workStationCode?: string): Promise<any> => {
        return service({
            url: '/Camera/GetCameras',
            params: { lineCode, workStationCode }
        })

    },

    /**
     * 获取机器人的当前状态
     * @param {number} lineCode
     * @param {number} workStationCode
     */
    getRobotCurrentStatusByLocation: (lineCode?: string, workStationCode?: string): Promise<any> => {
        return service({
            url: '/Equipment/GetRobotCurrentStatusByLocation',
            params: { lineCode, workStationCode }
        })

    },


    getInstalledLineCodes: (lineCode?: string) => {
        return service({
            url: '/Equipment/GetInstalledLineCodes',
            params: { lineCode }
        })
    },

    /**
     * 获取各产线电力的最新采集记录
     */
    getLineCurrentElectricities: (lineCode?: string) => {
        return service({
            url: '/Equipment/GetLineCurrentElectricities',
            params: { lineCode }
        })
    },

    /**
     * 获取今日能耗
     * @param lineCode 
     * @returns 
     */
    getTodayElectricityQuantityInKWH: (lineCode?: string): Promise<any> => {
        return service({
            url: '/Equipment/GetTodayElectricityQuantityInKWH',
            params: { lineCode: lineCode }
        })
    },

    /**
     * 获取累积用电量
     * @param timeRange 
     * @returns 
     */
    getTotalElectricityQuantityInKWH: (lineCode?: string): Promise<any> => {
        return service({
            url: '/Equipment/GetTotalElectricityQuantityInKWH',
            params: { lineCode }
        })
    },

    /**
     * 获取胶罐液位和压力
     * @param lineCode 
     * @returns 
     */
    getGlueBucketCurrentParameters: (lineCode?: string): Promise<any> => {
        return service({
            url: '/Equipment/GetGlueBucketCurrentParameters',
            params: { lineCode: lineCode }
        })
    },

    /**
     * 获取机器人状态
     * @param lineCode
     */
    getRobotCurrentStatuses: (lineCode?: string): Promise<any> => {
        return service({
            url: '/Equipment/GetRobotCurrentStatuses',
            params: { lineCode }
        })

    },

}



export default Equipment
