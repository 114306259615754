import React, { useState } from 'react';
import './index.scss';
import { TimeBar, RegionBar, CardBar } from '@/components/bar';
import { BasicLayout, LineLayout } from '@/layout'
import { Card } from '@/components/card';
import { DigitalBlock } from '@/components/digitalBlock';
import * as Charts from '@/components/charts';
import { BasicUnderLine } from '@/components/underLine';
import { searchParams, handleDataForOvenLineChart, handleDataForLineChart } from '@/utils';
import { getTimeRangeByUrl } from '@/utils/time';
import * as API from '@/api/request';
import { useRequest } from 'ahooks';
import Loading from '@/components/loading/loading';
import { Error } from '@/components/error';
import FormatterType from '@/components/charts/formatterType';
import { store } from '@/store';
const lineCode = searchParams().lineCode || null;

// const ovenName = ['烘箱1', '烘箱2', '烘箱3', '热定型', '烘箱4', '烘箱5', '烘箱6', '冷定型'];

const Oven = () => {
    const [ovensCurrentParameters, setOvensCurrentParameters] = useState([]);
    const [timeRange, setTimeRange] = useState(getTimeRangeByUrl())
    useRequest(() => { return API.Equipment.getOvensCurrentParameters(lineCode) }, {
        onSuccess: (data) => {
            if (data.length !== 0) {
                // console.log('data', data);
                setOvensCurrentParameters(data)
            }
        }
    });


    const { loading, data, error } = useRequest(() => API.Equipment.getOvenElectricity(lineCode, timeRange), { refreshDeps: [timeRange] });

    const ovensData = store.getState().ovenOrRefrigerators;
    // console.log('烘箱页烘箱', ovensData);
    const ovesnWithTemperatureInC = ovensData.map((oven: any) => {
        let dataItme = ovensCurrentParameters.find((item: any) => oven.code === item.ovenCode)
        return {
            ...oven,
            temperatureInC: dataItme ? dataItme.temperatureInC : null,
            powerInW: dataItme ? dataItme.powerInW : null
        }
    })

    const ovenTop = <>
        <RegionBar title='温度状态' />
        <Card customBar={<React.Fragment />} height={40.5 * ovesnWithTemperatureInC.length} margin={[0, 0, 20, 0]}>
            <LineLayout justify='start' direction='column'>
                {ovesnWithTemperatureInC.map((item: any, index: number) => {
                    return <LineLayout justify='between' width={345} key={index}>
                        <CardBar title={item.name} icon={`${index === 3 ? 'heatSetting' : index === 7 ? 'coldSetting' : 'oven'}.png`} hideForward size={0.8} width={130} />
                        {index === ovesnWithTemperatureInC.length - 1 ? '' : <BasicUnderLine />}
                        {
                            item ? <DigitalBlock value={item.temperatureInC} size={0.8} unit='℃' width={85} textAlign='right'
                                color={item.name === '冷定型' ? '#00e2ff' : item.name === '热定型' ? '#ff2800' : 'orange'}
                            /> : <p style={{ fontWeight: 'bold' }}>--</p>
                        }

                        {
                            item ? <DigitalBlock value={item.powerInW / 1000} size={0.8} unit='kW' width={85} textAlign='right' /> : <p style={{ fontWeight: 'bold' }}>--</p>
                        }
                    </LineLayout>
                })}
            </LineLayout>
        </Card>
        <TimeBar change={(timeRange: any) => { setTimeRange(timeRange) }} selected={timeRange.timeRangeType} size={0.85} height={35} />
    </>

    if (loading) return (<BasicLayout name='烘箱'>
        {ovenTop}
        <Loading />
    </BasicLayout>)
    if (error) return (<BasicLayout name='烘箱'>
        {ovenTop}
        <Error />
    </BasicLayout>)

    // console.log('data', data);
    //烘箱
    let ovens = [];
    let ovensName = [];
    //冷定型
    let cold;
    //热定型
    let hot;

    data.forEach((item: any) => {
        if (item.ovenName === '热定型') {
            hot = item;
        } else if (item.ovenName === '冷定型') {
            cold = item;
        } else {
            ovens.push(item);
            ovensName.push(item.ovenName);
        }
    });

    // console.log('ovens', ovens);
    return (
        <BasicLayout name='烘箱'>
            {ovenTop}
            <LineLayout justify='around' padding={[15, 0, 15, 0]} gap={10} direction='column'>
                <Card title='各烘箱能耗趋势' icon='polygonalLine.png'>
                    <Charts.StackedLineChart width={355} height={200}
                        legend={ovensName}
                        dataX={handleDataForOvenLineChart((timeRange as any).timeRangeType, ovens).xAxis}
                        dataY={handleDataForOvenLineChart((timeRange as any).timeRangeType, ovens, ovensName).series}
                    />
                </Card>
                <Card title='热定型能耗趋势' icon='line.png'>
                    <Charts.BasicLineChart width={355} height={200}
                        dataX={handleDataForLineChart((timeRange as any).timeRangeType, hot.values).xAxis}
                        dataY={handleDataForLineChart((timeRange as any).timeRangeType, hot.values).yAxis}
                        formatter={timeRange.timeRangeType === 0 ? FormatterType.TodayMoment : null}
                    />
                </Card>
                <Card title='冷定型能耗趋势' icon='line.png'>
                    <Charts.BasicLineChart width={355} height={200}
                        dataX={handleDataForLineChart((timeRange as any).timeRangeType, cold.values).xAxis}
                        dataY={handleDataForLineChart((timeRange as any).timeRangeType, cold.values).yAxis}
                        formatter={timeRange.timeRangeType === 0 ? FormatterType.TodayMoment : null}
                    />
                </Card>
            </LineLayout>
        </BasicLayout>
    )
}

export { Oven }